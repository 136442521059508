/*
 * @Description: 前台权限列表
 * @Author: rongcheng
 * @@后台人员: 郑晨茹
 * @Date: 2022-02-23 16:50:30
 * @LastEditors: rongcheng
 * @LastEditTime: 2022-04-18 11:04:16
 */
enum types {
  目录 =0,
  菜单,
  按钮
}
export enum stateType {
  禁用=0,
  启用 =1
}
export const getTableConfig = () => {
  // table 通用属性
  const common = {
    // table 有多选时设置
    isSelection: false,
    // table loading
    loading: false,
    // table 是否为斑马纹
    stripe: true,

    isIndex: true,
    // table 索引 label
    indexLabel: '序号',
    // table 的尺寸 medium / small / mini
    size: 'small',
    rowKey: 'id',
    defaultExpandAll: false,
    // table 数据
    data: []
  }

  // table 分页
  const pagination = {
    // 是否展示分页
    isShow: false,
    // 总条数
    total: 0,
    // 每页显示条目个数
    pageSize: 20,
    // 当前页数
    currentPage: 1,
    // 每页显示个数选择器的选项设置
    pageSizes: [10, 20, 40, 80, 100]
  }

  // table 列数据
  const column = [
    // {
    //   key: 'enterpriseCode',
    //   label: '功能模板',
    //   width: '140'
    // },
    {
      key: 'menuName',
      label: '菜单名',
      width: '140'
    },
    // {
    //   key: 'contacts',
    //   label: '二级权限',
    //   width: '140'
    // },
    {
      key: 'type',
      label: '权限',
      width: '140',
      formatter: (row, col, val) => {
        return types[val]
      }
    },
    {
      key: 'icon',
      label: '图标',
      width: '140'
    },
    {
      key: 'sort',
      label: '排序',
      width: '140'
    },
    {
      key: 'shortcutMenu',
      label: '快捷菜单',
      width: '120',
      slot: 'shortcutMenu'
    },
    {
      key: 'enabled',
      label: '状态',
      width: '140',
      slot: 'enabled'

    },
    {
      key: 'action',
      label: '操作',
      width: '140',
      fixed: 'right',
      slot: 'action'
    }
  ]

  return {
    ...common,
    pagination,
    column
  }
}

/*
 * @Description:
 * @Author: rongcheng
 * @@后台人员: xxx
 * @Date: 2022-03-15 15:26:26
 * @LastEditors: rongcheng
 * @LastEditTime: 2022-04-02 16:12:14
 */

import service from '@/utils/request'

// 获取树形结构
export const getMenuTree = (params: any) => {
  return service.request({
    method: 'post',
    url: '/menu/menuTree',
    data: params
  })
}
// 新增菜单
export const setPermissionAdd = (params: any) => {
  return service.request({
    method: 'post',
    url: '/menu/add',
    data: params
  })
}
// 修改菜单
export const setPermissionEdit = (params: any) => {
  return service.request({
    method: 'post',
    url: '/menu/edit',
    data: params
  })
}
// 菜单详情
export const getPermissionDetail = (params: any) => {
  return service.request({
    method: 'post',
    url: '/menu/detail',
    data: params
  })
}


import { defineComponent, reactive, toRefs, onMounted } from 'vue'
import { getTableConfig } from './config'
import { btnName } from '@/utils/index'
import CTable from '@/components/c-table'
import { ElMessage, ElMessageBox } from 'element-plus'
import editDialog from './components/edit-dialog.vue'
import menuDialog from './components/menu-dialog.vue'
import { getMenuTree, setPermissionRemove } from './api'
import { toResult } from '@/utils/toResult'
export default defineComponent({
  components: {
    CTable,
    editDialog,
    menuDialog
  },
  setup() {
    // 表单组件变量
    const tableConfig = reactive({
      ...getTableConfig()
    })
    const state = reactive({
      visible: false,
      code: 2,
      dialogTitle: '新增',
      rowData: {}
    })
    const getListApi = async () => {
      tableConfig.loading = true
      const [err, res] = await toResult(getMenuTree({
        excludeNoAuth: false,
        platformCode: state.code,
        currPage: tableConfig.pagination.currentPage,
        pageSize: tableConfig.pagination.pageSize
      }))
      if (err) return false
      tableConfig.loading = false
      tableConfig.data = res[0].children
      tableConfig.pagination.total = res.total
    }

    const handleReset = () => {
      getListApi()
    }
    const handleRadioChange = () => {
      getListApi()
    }
    /**
     * @description: 二次确认框
     * @param {*} title 提示信息
     * @param {*} name  标题
     * @param {*} btnName 按钮名字
     * @return {promise}
     */
    const confirm = (
      title: string,
      name: string,
      btnName: string
    ): Promise<string> => {
      return ElMessageBox.confirm(title, name, {
        confirmButtonText: btnName,
        showCancelButton: false,
        type: 'warning'
      })
    }
    const setRemove = async (params) => {
      confirm(
        '确定要删除吗？',
        '删除',
        '确定'
      ).then(async () => {
        const [err, res] = await toResult(setPermissionRemove({
          platformCode: state.code,
          tag: params.tag
        }))
        if (err) return false
        ElMessage.success('删除成功!')
        getListApi()
        console.log(res)
      })
    }
    const enterpriseTarget = (path, row) => {
      if (path === 'enterprise-permission-delete') {
        setRemove(row)
        return false
      }
      path === 'enterprise-permission-add' && (state.dialogTitle = '新增')
      if (path === 'enterprise-permission-edit') {
        state.dialogTitle = '修改'
        state.rowData = row
      }
      state.visible = true
    }
    const pageCurrentChange = (val: number) => {
      tableConfig.pagination.currentPage = val
      getListApi()
    }
    const pageSizeChange = (val: number) => {
      tableConfig.pagination.currentPage = 1
      tableConfig.pagination.pageSize = val
      getListApi()
    }
    onMounted(() => {
      getListApi()
    })
    return {
      enterpriseTarget,
      handleRadioChange,
      btnName,
      handleReset,
      tableConfig,
      pageCurrentChange,
      pageSizeChange,
      ...toRefs(state)
      // ...toRefs(state),
    }
  }
})

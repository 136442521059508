/*
 * @Description:
 * @Author: rongcheng
 * @@后台人员: xxx
 * @Date: 2022-03-15 17:39:48
 * @LastEditors: rongcheng
 * @LastEditTime: 2022-03-15 17:56:00
 */
import { isArray, isString } from '@/utils/is'
// import eIconList from './eIconList'
import elementUI from './elementUI'
// import fontAwesome from './fontAwesome'

const add = function (list, item) {
  let arr = []
  if (item && isArray(item)) {
    arr = list.concat(item)
  } else if (item && isString(item)) {
    arr = arr.concat(list)
    arr.push(item)
  }
  return arr
}

const remove = function (list, item) {
  if (item && isArray(item)) {
    for (let i = 0; i < item.length; i++) {
      for (let j = 0; j < list.length; j++) {
        if (list[j] === item[i]) {
          list.splice(j, 1)
          j--
        }
      }
    }
  } else if (item && isString(item)) {
    list = list.filter(function (i) {
      return i !== item
    })
  }
  return list
}

const iconList = {
  list: [],
  /**
     * 全局添加图标
     * @param item
     */
  addIcon: function (item) {
    this.list = add(this.list, item)
  },
  /**
     * 全局删除
     * @param item
     */
  removeIcon: function (item) {
    this.list = remove(this.list, item)
  }
}

export { elementUI }
export default iconList


import { reactive, readonly, toRefs, ref, defineComponent, nextTick } from 'vue'
import iconList, { elementUI } from './js/iconList'
export default defineComponent({
  name: 'MenuIcons',
  props: {
    modelValue: {
      type: String,
      default: ''
    }
  },
  setup(props, context) {
    const popover = ref(null)
    const getData = () => {
      for (const name of elementUI) {
        iconList.addIcon(name)
      }
      return iconList.list
    }
    const iconsData = readonly(getData())
    const state = reactive({
      // list: iconsData,
      show: false
    })
    const handleClear = () => {
      context.emit('update:modelValue', '')
    }
    const onSelect = (icon) => {
      context.emit('update:modelValue', icon)
      nextTick(() => {
        state.show = false
      })
    }
    return {
      popover,
      handleClear,
      iconsData,
      ...toRefs(state),
      onSelect
    }
  }
})

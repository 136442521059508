/*
 * @Description:
 * @Author: rongcheng
 * @@后台人员: xxx
 * @Date: 2022-03-15 14:19:29
 * @LastEditors: rongcheng
 * @LastEditTime: 2022-03-15 14:19:29
 */
import cSelectTree from './index.vue'
export default cSelectTree

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "menu-icons" }
const _hoisted_2 = { class: "icon-delete-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_row = _resolveComponent("el-row")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_popover = _resolveComponent("el-popover")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_el_popover, {
      ref: "popover",
      visible: _ctx.show,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (_ctx.show = $event)),
      placement: "bottom-start",
      trigger: "click",
      "popper-class": "popper-menu-icon",
      width: "480"
    }, {
      reference: _withCtx(() => [
        _createVNode(_component_el_input, {
          value: _ctx.modelValue,
          readonly: "",
          placeholder: "请选择"
        }, {
          prepend: _withCtx(() => [
            _createVNode(_component_el_icon, { class: _ctx.modelValue }, null, 8, ["class"])
          ]),
          suffix: _withCtx(() => [
            _createVNode("div", _hoisted_2, [
              _createVNode(_component_el_icon, {
                class: "el-icon-circle-close",
                onClick: _ctx.handleClear
              }, null, 8, ["onClick"])
            ])
          ]),
          _: 1
        }, 8, ["value"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_row, {
          gutter: 10,
          class: "list"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.iconsData, (item, index) => {
              return (_openBlock(), _createBlock(_component_el_col, {
                span: 3,
                key: index
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    class: { 'is-active': item === _ctx.modelValue },
                    onClick: ($event: any) => (_ctx.onSelect(item))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_icon, { class: item }, null, 8, ["class"])
                    ]),
                    _: 2
                  }, 1032, ["class", "onClick"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}
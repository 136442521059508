
import { defineComponent, reactive, ref, toRefs, onMounted, watch } from 'vue'
// import { ElMessage } from 'element-plus'

export default defineComponent({

  props: {
    visible: Boolean,
    rowData: Object
  },
  setup(props, { emit }) {
    const data = reactive({
      formRef: ref<HTMLElement | null>(null),
      formData: ref<any>({}),
      isDialog: false
    })
    const options = ref([])

    const rules = {
      vin: [
        { required: true, message: '必填', trigger: 'blur' }
        // {
        //   pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{2,25}$/,
        //   message: '只支持输入最多25位数字、字母组合',
        //   trigger: 'blur'
        // }
      ],
      changeTime: [{ required: true, message: '必填', trigger: 'blur' }]
    }
    watch(() => props.visible, (n) => {
      data.isDialog = n
    })
    // 关闭弹框
    const handleCloseDialog = () => {
      emit('close-dialog', false)
    }

    const remoteMethod = value => {
      options.value = []
      if (value !== '') {
      }
    }
    // 保存提交数据
    const submitData = () => {
      const ref: any = data.formRef
      ref.validate((valid: boolean) => {
        if (valid) {

        }
      })
    }

    onMounted(() => { })

    return {
      ...toRefs(data),
      options,
      rules,
      handleCloseDialog,
      submitData,
      remoteMethod
    }
  }
})


import { defineComponent, ref, watch, onMounted, toRaw } from 'vue'

export default defineComponent({
  name: 'mySelect',
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    valueName: {
      type: String,
      default: 'value'
    },
    defaultProps: {
      type: Object,
      default: () => {
        return {
          children: 'children',
          label: 'label'
        }
      }
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  emits: ['nodeClick', 'update:modelValue'],
  setup(props, context) {
    const optionValue = ref('')
    const optionLabel = ref('')
    const mySelect = ref()
    const getTreeLabel = () => {
      return props.defaultProps.label
    }
    function getLable(arr: any, value: string) {
      let res = ''
      function find(arr, value) {
        for (let i = 0; i < arr.length; i++) {
          const val = props.valueName || 'value'
          const label = getTreeLabel() || 'label'
          if (arr[i][val] === value) {
            res = arr[i][label]
          }
          if (arr[i].children && arr[i].children.length) {
            find(arr[i].children, value)
          }
        }
      }
      find(arr, value)
      return res
    }
    watch(() => props.modelValue, (n) => {
      optionValue.value = getLable(toRaw(props.options), n)
    })
    onMounted(() => {
      optionValue.value = getLable(toRaw(props.options), props.modelValue)
    })

    function handleNodeClick(node) {
      optionLabel.value = node.label
      optionValue.value = node[props.valueName]
      context.emit('nodeClick', node)
      context.emit('update:modelValue', optionValue.value)
      console.log(optionValue.value, 'node')
      mySelect.value.blur()
    }
    return {
      mySelect,
      handleNodeClick,
      optionValue,
      optionLabel
    }
  }
})


import { defineComponent, reactive, ref, toRefs, watch, computed } from 'vue'
import cSelectTree from '@/components/c-select-tree/index'
import { ElMessage } from 'element-plus'
import IconPicker from '@/components/c-icon-picker/index.vue'
import { getMenuTree, setPermissionAdd, getPermissionDetail, setPermissionEdit } from './api'
import { toResult } from '@/utils/toResult'
// 表单常规非空校验
const required = (trigger = 'blur', msg = '必填') => {
  return {
    required: true,
    message: msg,
    trigger: trigger
  }
}
export default defineComponent({
  components: {
    cSelectTree,
    IconPicker
  },
  props: {
    visible: Boolean,
    rowData: Object,
    title: String,
    code: Number // 平台权限  1 admin  2 SAAS 权限
  },
  setup(props, { emit }) {
    const data = reactive({
      formRef: ref<HTMLElement | null | any>(null),
      formData: ref<any>({
        menuName: '',
        enabled: 1,
        display: 1,
        shortcutMenu: 0
      }),
      isDialog: false
    })
    const options = ref([])
    const rules = {
      tag: [required(), {
        type: 'string',
        pattern: /^[0-9a-zA-Z__@!#$&%-.^?]{1,128}$/,
        message: '请输入1-32字符'
      }],
      parentTag: [required('change')],
      type: [required()],
      menuName: [required()],
      // icon: [required('change')],
      enabled: [required('change')],
      apiAddress: [required()],
      shortcutMenu: [required()],
      sort: [required()]
      // component: required(),
      // path: required()
    }
    const isDisable = computed(() => props.title !== '新增')
    // 关闭弹框
    const handleCloseDialog = () => {
      data.formRef.resetFields()
      emit('update:visible', false)
    }
    // 刷新列表
    const reset = () => {
      emit('reset-change')
    }
    const getMenuTreeApi = () => {
      getMenuTree({
        excludeNoAuth: true,
        platformCode: props.code
      }).then(res => {
        options.value = res.data
        console.log(options, 'options')
      })
    }

    const getDetail = async () => {
      console.log(props.rowData)
      const p = {
        platformCode: props.code,
        tag: props.rowData.tag
      }
      const [err, res] = await toResult(getPermissionDetail(p))
      if (err) return false
      data.formData = Object.assign(res, {
        path: res.componentRouting
      })
      console.log(res, 'res')
    }
    const getAddFn = async () => {
      const p = Object.assign(data.formData, {
        icon: data.formData.icon || '',
        platformCode: props.code,
        componentRouting: data.formData.path
      })
      // if (data.formData.type === 0) p.parentTag = 0
      const [err, res] = await toResult(setPermissionAdd(p))
      if (err) return false
      ElMessage.success('新增成功!')
      handleCloseDialog()
      reset()
      console.log(res)
    }
    const getEditFn = async () => {
      const p = Object.assign(data.formData, {
        icon: data.formData.icon || '',
        platformCode: props.code,
        componentRouting: data.formData.path
      })
      // if (data.formData.type === 0) p.parentTag = 0
      const [err, res] = await toResult(setPermissionEdit(p))
      if (err) return false
      console.log(res)
      ElMessage.success('修改成功!')
      handleCloseDialog()
      reset()
    }
    // 保存提交数据
    const submitData = () => {
      const ref = data.formRef
      ref.validate((valid: boolean) => {
        if (!valid) {
          return false
        }
        if (data.formData.shortcutMenu === 1) {
          if (!data.formData.shortcutMenuSort) {
            ElMessage.error('快捷菜单排序必填')
            return false
          }
          // if (!(/(^[1-9]\d*$)/.test(data.formData.shortcutMenuSort))) {
          //   ElMessage.error('必须是数字')
          //   return false
          // }
        }
        props.title === '新增' && getAddFn()
        props.title === '修改' && getEditFn()
      })
    }
    watch(() => [props.visible, props.rowData], (n: any[]) => {
      data.isDialog = n[0]
      if (n[0]) {
        getMenuTreeApi()
        props.title === '修改' && getDetail()
      }
    })

    return {
      ...toRefs(data),
      isDisable,
      options,
      rules,
      handleCloseDialog,
      submitData
    }
  }
})
